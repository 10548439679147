<!-- 志愿者团队新增弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;" title="" layout="vertical">
        <a-descriptions-item label="姓名">
          {{
            personInforList.residentName ? personInforList.residentName : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号" >
          {{
            personInforList.identityCard ? personInforList.identityCard : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="联系方式">
          {{
            volunteerInfoList.contactWay ? volunteerInfoList.contactWay : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="所属网格" >
          {{ personInforList.gridName ? personInforList.gridName : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="详细地址" :span="2">
          {{ volunteerInfoList.address ? volunteerInfoList.address : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="申请理由" :span="3">
          {{
            volunteerInfoList.applyReason ? volunteerInfoList.applyReason : "--"
          }}
        </a-descriptions-item>
      </a-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          style="background-color: #2A5CFF"
          :loading="saveType"
          >同 意</el-button
        >
        <el-button @click="onCancel">拒 绝</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :z-index="20"
      title="拒绝理由"
      :visible.sync="visible1"
      width="40%"
      @close="closeModal1"
      :close-on-click-modal="false"
    >
      <el-input
        type="textarea"
        placeholder="请输入"
        v-model="rejectReason"
        maxlength="200"
        show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const formList = [
  {
    label: "团队名称",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入...", maxLength: 20 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入姓名" }],
  },
  {
    label: "团长姓名",
    type: "",
    model: "leaderName",
    options: { allowClear: true, placeholder: "请输入..." },
    col: { span: 24 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "联系方式",
    type: "",
    model: "identityCard",
    options: { placeholder: "请输入", maxLength: 11 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        pattern:
          /^134[0-8]\d{7}$|^13[^4]\d{8}$|^14[5-9]\d{8}$|^15[^4]\d{8}$|^16[6]\d{8}$|^17[0-8]\d{8}$|^18[\d]{9}$|^19[8,9]\d{8}$/,
        message: "请输入正确格式的联系方式",
      },
    ],
  },
  {
    label: "所属网格",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请选择团长" },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "请选择团长",
      },
    ],
  },
  {
    label: "详细地址",
    type: "",
    model: "age",
    options: {
      disabled: false,
      placeholder: "请输入",
      maxLength: 50,
    },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "团队介绍",
    type: "",
    model: "teamIntroduction",
    options: {
      disabled: false,
      placeholder: "请输入",
      minRows: 4,
      maxLength: 200,
    },
    col: { span: 24 },
    rules: [{ required: false, message: "请输入" }],
  },
];
import api from "./api";
export default {
  data() {
    return {
      visible: false,
      visible1: false,
      id: "",
      title: "",
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      leaderName: "", //团长姓名
      content: "", //富文本
      msgContent: "", //富文本内容
      personInforList: {},
      volunteerInfoList: {},
      rejectReason: "", //拒绝理由
    };
  },
  watch: {},
  computed: {},
  async mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.id = record.id;
      // this.personInforList = record;
      this.visible = true;
      this.title = record.title;
      // this.id = record.id;
      this.getPersonInfo(record.residentId);
      this.volunteerInfo(record.id);
      this.saveType = false;
    },
    // 根据id获取居民详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.personInforList = res.data;
    },
    // 根据id获取志愿者详情
    async volunteerInfo(id) {
      const res = await api.getVolunteerInfoById(id);
      this.volunteerInfoList = res.data;
    },
    leaderNameChange(e, type) {
      console.log(e, type);
    },
    // 打开选择团长弹窗
    chooseTeamLeader() {
      this.$refs.chooseTeamLeader.openModal();
    },

    // receiveTeamLeaderId(item) {
    // console.log(item, "398");
    // if (item.id) {
    //   this.leaderName = item.residentName;
    // 为所属网格赋值
    // if (item.cellName) {
    //   const gridName = `${item.cellName}/${item.buildingNum}/${item.unitNum}/${item.floorNum}/${item.accountNum}`;
    //   this.$refs.form.setFieldsValue({ gender: gridName });
    // }
    // }
    // },
    // 富文本编辑器内容变化触发
    // handleEditorChange(html) {
    //   this.msgContent = html;
    //   console.log(html, "161");
    // },
    // 关闭弹窗
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.$emit("refsh");
    },
    // 关闭弹窗
    closeModal1() {
      this.visible1 = false;
      this.rejectReason = ''
      this.$emit("refsh");
    },
    // 获取字典
    // async getDictionsary() {
    //   // 民族
    //   await api.dictData({ dicKind: "nation" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("nation", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 宗教
    //   await api.dictData({ dicKind: "religion" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("religion", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 职业
    //   await api.dictData({ dicKind: "job" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("job", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 兵役状态
    //   await api.dictData({ dicKind: "military_status" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("militaryStatus", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 婚姻状态
    //   await api.dictData({ dicKind: "marital_status" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("maritalStatus", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 文化程度
    //   await api.dictData({ dicKind: "educational_level" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("educationalLevel", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   // 政治面貌
    //   await api.dictData({ dicKind: "political_status" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     this.$refs.form.setFormItemProp("politicalStatus", {
    //       options: { options, allowClear: true, placeholder: "请选择" },
    //     });
    //   });
    //   //人员关系
    //   await api.dictData({ dicKind: "personnel_relation" }).then((res) => {
    //     const options = res.data.map((res) => {
    //       return { label: res.dicDesc, value: res.dicCode };
    //     });
    //     console.log(this.flag);
    //     if (!this.flag || this.flag === "1") {
    //       console.log(111);
    //       this.$refs.form2.setFormItemProp("houseHolderRelation", {
    //         options: {
    //           options,
    //           allowClear: true,
    //           placeholder: "请选择",
    //           hide: false,
    //         },
    //       });
    //     } else {
    //       this.$refs.form2.setFormItemProp("houseHolderRelation", {
    //         options: {
    //           hide: true,
    //         },
    //       });
    //     }
    //   });
    // },
    // 审核通过(同意)
    async onSubmit() {
      this.saveType = true;
      const volunteerInfo = {
        id: this.id,
        auditStatus: "1",
      };
      const res = await api.auditVolunteerInfo(volunteerInfo);
      console.log(res, "307");
      if (res.status === 200) {
        this.$message.success("审核通过");
        this.saveType = false;
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("审核失败");
      }
    },
    onCancel() {
      this.visible1 = true;
    },
    // 审核不通过(拒绝)
    async sure() {
      this.saveType = true;
      const volunteerInfo = {
        id: this.id,
        auditStatus: "2",
        rejectReason: this.rejectReason,
      };
      const res = await api.auditVolunteerInfo(volunteerInfo);
      console.log(res, "307");
      if (res.status === 200) {
        this.saveType = false;
        this.$message.success("审核未通过,请在小程序重新发起申请");
        this.closeModal1();
        this.closeModal();
      } else {
        this.saveType = false;
        this.$message.success("审核失败");
      }
    },
    // 根据id获取详情
    // async getPersonInfo(id) {
    // const res = await api.getResidentArchivesById(id);
    // console.log(res.data, "594");
    // this.$refs.form.setFieldsValue(res.data);
    // this.$refs.form1.setFieldsValue(res.data);
    // this.$refs.form2.setFieldsValue(res.data);
    // this.nativePlace = res.data.nativePlace;
    // this.buildingNum = res.data.buildingNum;
    // this.unitNum = res.data.unitNum;
    // this.floorNum = res.data.floorNum;
    // this.accountNum = res.data.accountNum;
    // this.houseHolderCard = res.data.houseHolderCard;
    // this.houseHolderRelation = res.data.houseHolderRelation;
    // if (res.data.cellName) {
    //   this.detailedAddress = `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`;
    // } else {
    //   this.detailedAddress = "";
    // }
    // this.flag = res.data.flag;
    // this.currentArea = res.data.currentArea;
    // this.nativeCode = res.data.nativeCode;
    // this.currentAreaCode = res.data.currentAreaCode;
    // this.residenceCode = res.data.residenceCode;
    // this.residencePlace = res.data.residencePlace;
    // this.cellName = res.data.cellName;
    // this.calculateIDCardInfo(res.data.identityCard);
    // if (res.data.flag === "0") {
    //   this.$refs.form2.setOptions(
    //     ["houseHolderCard", "houseHolderRelation"],
    //     "hide",
    //     true
    //   );
    // } else {
    //   this.$refs.form2.setFieldsValue({
    //     houseHolderCard: res.data.houseHolderCard,
    //   });
    //   this.$refs.form2.setFieldsValue({
    //     houseHolderRelation: res.data.houseHolderRelation,
    //   });
    //   this.$refs.form2.setOptions(["houseHolderCard"], "hide", false);
    //   this.$refs.form2.setOptions(["houseHolderRelation"], "hide", false);
    // }
    // },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2A5CFF;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}

.search-title {
  font-weight: 800;
}
</style>